import { ApolloClient, InMemoryCache } from '@apollo/client';
import { commercetoolsHttpLink } from './httpLink';

export const createNewCommercetoolsClient = (name: string = 'commercetoolsClient') => {
  return new ApolloClient({
    name,
    link: commercetoolsHttpLink,
    cache: new InMemoryCache({
      addTypename: true,
    }),
    ssrMode: typeof window === 'undefined',
  });
};
