import type { ApolloClient } from '@apollo/client';
import { createNewCommercetoolsClient } from './createCommercetoolsClient';

let commercetoolsClient: ApolloClient<any> | null = null;

export const toCommercetoolsClient = (name: string = 'commercetoolsClient') => {
  if (!commercetoolsClient) {
    commercetoolsClient = createNewCommercetoolsClient(name); // Create a new client if it doesn't exist
  }
  return commercetoolsClient;
};
